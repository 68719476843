<nav class="navbar is-fixed-top is-light">
  <!-- logo -->
  <div class="navbar-brand">
    <a class="navbar-item">
      <i class="fas fa-laptop-code fa-2x"></i>
    </a>
    <a
      role="button"
      class="navbar-burger burger"
      aria-label="menu"
      aria-expanded="false"
      data-target="navbarHeader"
      onclick="toggleBurger()"
    >
      <span></span>
      <span></span>
      <span></span>
    </a>
  </div>

  <div id="navbarHeader" class="navbar-menu">
    <div class="navbar-start">
      <a class="navbar-item" routerLink="">Home</a>
      <a class="navbar-item" routerLink="contact">Contact</a>
      <a
        href="https://jasonxblue.github.io/resume/Jason-Blue-Resume.pdf"
        class="navbar-item"
        >Resume</a
      >
    </div>
    <div class="navbar-end">
      <a href="https://www.linkedin.com/in/jason-blue-x/">
        <i class="fab fa-linkedin fa-3x px-1"></i>
      </a>
      <a href="https://github.com/JasonXBlue">
        <i class="fab fa-github-square fa-3x px-1"></i>
      </a>
    </div>
  </div>
</nav>
