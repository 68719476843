import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  burger: any;
  nav: any;

 toggleBurger() {
   const burger = document.querySelector('burger');
   const nav = document.querySelector('#navbarHeader');
   burger.classList.toggle('is-active');
   nav.classList.toggle('is-active');
 }

}
