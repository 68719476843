<section class="hero is-fullheight is-bold" id="body">
  <div class="hero-body">
    <div class=""></div>
    <main class="site-content has-text-centered">
      <div>
        <h1 class="a">H</h1>
        <h1 class="a">i</h1>
        <h1 class="a">,</h1>
        <br />
        <span class="a">I</span>
        <span class="a">'</span>
        <span class="a">m&nbsp;</span>
        <span class="a"> </span>
        <span class="a">J</span>
        <span class="a">a</span>
        <span class="a">s</span>
        <span class="a">o</span>
        <span class="a">n</span>
        <span class="a">,</span>
        <br />
        <span class="a">w</span>
        <span class="a">e</span>
        <span class="a">b&nbsp;</span>

        <span class="a">d</span>
        <span class="a">e</span>
        <span class="a">v</span>
        <span class="a">e</span>
        <span class="a">l</span>
        <span class="a">o</span>
        <span class="a">p</span>
        <span class="a">e</span>
        <span class="a">r</span>
      </div>
    </main>
  </div>
</section>
<section class="section">
  <h1 class="title has-text-centered">My projects</h1>
  <h1 class="title has-text-centered">(Coming Soon!)</h1>
</section>
