<footer class="footer" id="foot">
  <div class="container">
    <div class="content has-text-centered">
      <small>
        Jason
        <span id="lastname">Blue</span>
        &copy; Copyright {{ currentYear }}
      </small>
    </div>
  </div>
  <!-- <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d212706.41297806916!2d-102.02852567060006!3d33.59135048154162!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86fe12add37ddd39%3A0x1af0042922e84287!2sLubbock%2C%20TX!5e0!3m2!1sen!2sus!4v1614731598910!5m2!1sen!2sus"
    width="400"
    height="250"
    style="border: 0"
    allowfullscreen=""
    loading="lazy"
  ></iframe> -->
</footer>
