<section class="hero is-fullheight is-bold">
  <section class="hero is-link">
    <div class="hero-body">
      <p class="title">Contact Form COMING SOON!</p>
      <p class="subtitle"></p>
    </div>
  </section>

  <!-- <section class="hero is-primary is-bold">
  <div class="hero-body">
    <div class="container">
      <h1 class="title">Contact Us!</h1>
    </div>
  </div>
</section>
<section class="section">
  <div class="container">
    form goes here -->

  <!-- </div>
</section> -->

  <!-- form goes here -->
  <!-- form goes here -->
  <div class="hero-body">
    <form (ngSubmit)="processForm()">
      <!-- name -->
      <div class="field">
        <div class="control has-icons-left has-icons-right">
          <input
            type="text"
            name="name"
            class="input"
            placeholder="Your Name"
            [(ngModel)]="name"
            required
            minlength="3"
            #nameInput="ngModel"
          />
          <span class="icon is-small is-left">
            <i class="fas fa-address-card"></i>
          </span>
          <span class="icon is-small is-right">
            <i class="fas fa-check"></i>
          </span>
        </div>
        <div class="help is-error" *ngIf="nameInput.invalid && nameInput.dirty">
          Name is required and needs to be at least 3 characters long.
        </div>
      </div>

      <!-- email -->
      <div class="field">
        <div class="control has-icons-left has-icons-right">
          <input
            type="email"
            name="email"
            class="input"
            placeholder="Your Email"
            [(ngModel)]="email"
            required
            email
            #emailInput="ngModel"
          />
          <span class="icon is-small is-left">
            <i class="fas fa-envelope"></i>
          </span>
          <span class="icon is-small is-right">
            <i class="fas fa-check"></i>
          </span>
        </div>
        <div
          class="help is-error"
          *ngIf="emailInput.invalid && emailInput.dirty"
        >
          Needs to be a valid email.
        </div>
      </div>

      <!-- message -->
      <div class="field">
        <textarea
          class="textarea"
          name="message"
          placeholder="What's on your mind?"
          [(ngModel)]="message"
          required
          #messageInput="ngModel"
        ></textarea>
      </div>
      <div class="help is-error" *ngIf="emailInput.invalid && emailInput.dirty">
        Your message is required!
      </div>

      <button type="submit" class="button is-link is-large">Submit</button>
    </form>
  </div>
</section>
